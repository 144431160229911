<template>
  <div>
    <validation-observer
      ref="accountRules"
      tag="form"
    >
      <b-row>
        <!-- Facebook -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Facebook"
            rules="facebook-url"
          >
            <b-form-group
              label="Facebook"
              label-for="facecbook"
            >
              <b-form-input
                id="facecbook"
                v-model="inpoDetails.social_facebook"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Facebook Link"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Twitter -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Twitter"
            rules="twitter-url"
          >
            <b-form-group
              label="Twitter"
              label-for="twitter"
            >
              <b-form-input
                id="twitter"
                v-model="inpoDetails.social_twitter"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Twitter Link"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Youtube -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Youtube"
            rules="youtube-channel"
          >
            <b-form-group
              label="Youtube"
              label-for="youtube"
            >
              <b-form-input
                id="youtube"
                v-model="inpoDetails.social_youtube"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Youtube Link"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Instagram -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Instagram"
            rules="instagram-url"
          >
            <b-form-group
              label="Instagram"
              label-for="instagram"
            >
              <b-form-input
                id="instagram"
                v-model="inpoDetails.social_instagram"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Instagram Link"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Website -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="website"
            rules="url"
          >
            <b-form-group
              label="Website"
              label-for="website"
            >
              <b-form-input
                id="website"
                v-model="inpoDetails.website"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Website Link"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Fax -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Fax Number"
            rules="min:6"
          >
            <b-form-group
              label="Fax"
              label-for="fax"
            >
              <b-form-input
                id="fax"
                v-model="inpoDetails.fax_number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Fax Number"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Tags -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Tag"
          >
            <b-form-group
              label="Tags"
              label-for="tags"
            >
              <v-select
                v-model="inpoDetails.tag_id"
                label="tag_name"
                multiple
                :reduce="tag => tag.id"
                :options="tagOptions"
                :close-on-select="false"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="$can('store','global-TagController')"
          class="mt-1"
        >
          <b-link
            v-b-modal.add-modal
            variant="primary"
          >
            <span class="text-nowrap">Add Tag</span>
          </b-link>
        </b-col>
      </b-row>
    </validation-observer>
    <b-modal
      id="add-modal"
      centered
      title="Add Tag"
      hide-footer
    >
      <add-tags
        tag-category="islamic organization"
        @refresh="$store.dispatch('generalSettings/getTagsRequest')"
      />
    </b-modal>
  </div>

</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import AddTags from '../../GeneralSettings/tags/AddTags.vue'

export default {
  components: {
    vSelect,
    AddTags,
  },
  props: {
    inpoDetails: {
      type: Object,
      default: () => {},
    },
    tagOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const variant = ref('dark')
    const option = ref(['Canada', 'United States'])
    const selected = ref(null)
    const selectedCategory = ref(null)
    const selectedPayment = ref(null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      variant,
      option,
      selected,
      selectedCategory,
      selectedPayment,
    }
  },
  methods: {
    addMulti() {
      this.inpoDetails.multi.push({ email: '', phone: '' })
    },
  },
}
</script>

<style lang="scss">
</style>
