<template>
  <div>
    <b-row>
      <b-col md="6">
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name"
          rules="required|min:3|max:200"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="inpoDetails.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <!-- Contact Name -->
        <validation-provider
          #default="validationContext"
          name="Contact Name"
          rules="min:3|max:200"
        >
          <b-form-group
            label="Contact Person"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="inpoDetails.contact_name"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Contact Person"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Mobile Number -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Mobile Number"
          rules="min:8|max:14|phone"
        >
          <b-form-group
            label="Mobile Number"
            label-for="mobile-number"
          >
            <b-form-input
              id="mobile-number"
              v-model="inpoDetails.phone_number"
              :state="getValidationState(validationContext)"
              trim
              type="tel"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Email -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="inpoDetails.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- About -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="About"
          rules="max:255"
        >
          <b-form-group
            label="About"
            label-for="about"
          >
            <b-form-textarea
              id="about"
              v-model="inpoDetails.about"
              :state="getValidationState(validationContext)"
              placeholder="About"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <b-form-group
          label="Has Prayers"
          label-for="has_prayers"
        >
          <b-checkbox
            id="has_prayers"
            v-model="inpoDetails.has_prayers"
            switch
          />
        </b-form-group></b-col>
    </b-row>
    <b-form-row class="mb-2">
      <!-- Classification -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Classification"
        >
          <b-form-group
            label="Classification"
            label-for="classification"
          >
            <v-select
              id="classifications"
              v-model="inpoDetails.classifications"
              multiple
              label="name"
              :reduce="d => d.id"
              :options="classificationsOptions"
              :close-on-select="false"
              :state="getValidationState(validationContext)"
              disabled
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- subClassification -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Sub Classification"
          rules="required"
        >
          <b-form-group
            label="Sub Classification"
            label-for="sub-classification"
          >
            <v-select
              id="sub-classification"
              v-model="inpoDetails.subclassifications"
              multiple
              label="name"
              :reduce="d => d.id"
              :options="subClassifications"
              :close-on-select="false"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Donation Category -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          rules=""
          name="Donation Category"
        >
          <b-form-group
            label="Donation Category"
            label-for="donation-category"
          >
            <v-select
              id="donation-category"
              v-model="inpoDetails.donation_cats_id"
              multiple
              label="category_name"
              :reduce="d => d.id"
              :options="donationCategories"
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Language"
          rules=""
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              id="language"
              v-model="inpoDetails.language_id"
              multiple
              :reduce="lang => lang.id"
              label="language_name"
              :options="languages"
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col
            md="6"
            lg="8"
          >
            <facilities-select
              ref="facilities-select"
              :facilities.sync="inpoDetails.facilities"
              :category="initialFacility[entityType].category"
            />
          </b-col>
          <b-col class="my-2 mb-md-0">
            <b-link
              v-b-modal.facility-add-modal
              variant="primary"
            >
              <span class="text-nowrap">Add Facility</span>
            </b-link>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col
            md="6"
            lg="8"
          >
            <services-select
              ref="services-select"
              :services.sync="inpoDetails.services"
              :category="initialService[entityType].category"
            />
          </b-col>
          <b-col class="my-2 mb-md-0">
            <b-link
              v-b-modal.service-add-modal
              variant="primary"
            >
              <span class="text-nowrap">Add Service</span>
            </b-link>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <products-select
          :products.sync="inpoDetails.products"
          category="islamic organization"
        />
      </b-col>

      <b-col md="6">
        <programs-select
          :programs.sync="inpoDetails.programs"
          category="islamic organization"
        />
      </b-col>

      <b-col md="6">
        <entity-select
          label="Entity Parent"
          :entity.sync="inpoDetails.parent"
          :filter="{classification: 'Organization', withoutParent: true}"
        />

        <b-checkbox
          v-model="inpoDetails.is_main_branch"
          class="my-1"
        >
          Main Branch
        </b-checkbox>
      </b-col>

      <!-- Modals -->
      <div>
        <!-- Add Facility -->
        <b-modal
          id="facility-add-modal"
          centered
          title="Add Facility"
          hide-footer
          no-close-on-backdrop
          size="lg"
          @hidden="resetFacility(entityType)"
        >
          <facility-form
            :facility="facility"
            :submit="addFacility"
            :is-category-enabled="false"
            :is-status-enabled="false"
          />
        </b-modal>

        <!-- Add Service -->
        <b-modal
          id="service-add-modal"
          centered
          title="Add Service"
          hide-footer
          no-close-on-backdrop
          size="lg"
          @hidden="resetService(entityType)"
        >
          <service-form
            :service="service"
            :submit="addService"
            :is-category-enabled="false"
            :is-status-enabled="false"
          />
        </b-modal>
      </div>
    </b-form-row>

    <location-inputs
      :details="inpoDetails"
    />

    <b-form-row>
      <b-col md="6">
        <b-form-row>
          <b-col cols="12">
            <upload-image
              id="inpo-logo"
              label="Logo"
              rules="max-dimension:1200,1200|image-aspect-ratio:1,1"
              :images.sync="inpoDetails.logo"
            />
          </b-col>

          <b-col cols="12">
            <images-base-64-preview
              file-id="inpo-logo"
              :images.sync="inpoDetails.logo"
            />
          </b-col>
        </b-form-row>
      </b-col>

      <b-col md="6">
        <b-form-row>
          <b-col cols="12">
            <upload-image
              id="inpo-cover"
              label="Cover"
              rules="image-aspect-ratio:2,1"
              :images.sync="inpoDetails.cover"
            />
          </b-col>

          <b-col cols="12">
            <images-base-64-preview
              file-id="inpo-cover"
              :images.sync="inpoDetails.cover"
            />
          </b-col>
        </b-form-row>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import LocationInputs from '@/common/components/common/FormInputs/LocationInputs.vue'
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import UploadImage from '../../common/FormInputs/UploadImage.vue'
import ImagesBase64Preview from '../../common/FormInputs/ImagesBase64Preview.vue'
import FacilitiesSelect from '../../GeneralSettings/facilities/FacilitiesSelect.vue'
import ServicesSelect from '../../GeneralSettings/services/ServicesSelect.vue'
import FacilityForm from '../../GeneralSettings/facilities/FacilityForm.vue'
import ServiceForm from '../../GeneralSettings/services/ServiceForm.vue'
import ProductsSelect from '../../GeneralSettings/products/ProductsSelect.vue'
import ProgramsSelect from '../../GeneralSettings/programs/ProgramsSelect.vue'
import EntitySelect from '../../Entities/EntitySelect.vue'

export default {
  components: {
    vSelect,
    LocationInputs,
    UploadImage,
    ImagesBase64Preview,
    FacilitiesSelect,
    ServicesSelect,
    FacilityForm,
    ServiceForm,
    ProductsSelect,
    ProgramsSelect,
    EntitySelect,
  },
  props: {
    inpoDetails: {
      type: Object,
      default: () => {},
    },
    donationCategories: {
      type: Array,
      default: () => [],
    },
    classificationsOptions: {
      type: Array,
      default: () => [],
    },
    subClassifications: {
      type: Array,
      default: () => [],
    },
    languages: { type: Array, default: () => [] },
  },
  computed: {
    entityType() {
      return 'islamicOrganization'
    },
  },
  setup() {
    const {
      initialItem: initialFacility, item: facility, addItemRequest: addFacilityRequest, resetItem: resetFacility,
    } = domainItemApi('entities', '/internalops/facility')

    const {
      initialItem: initialService, item: service, addItemRequest: addServiceRequest, resetItem: resetService,
    } = domainItemApi('entities', '/internalops/service')

    resetFacility('islamicOrganization')
    resetService('islamicOrganization')

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      facility,
      addFacilityRequest,
      addServiceRequest,
      service,
      refFormObserver,
      getValidationState,
      resetForm,
      initialFacility,
      initialService,
      resetFacility,
      resetService,
    }
  },
  methods: {
    addService() {
      return this.addServiceRequest(this.entityType).then(() => {
        this.$bvModal.hide('service-add-modal')
        this.$refs['services-select'].getServices()
      })
    },
    addFacility() {
      return this.addFacilityRequest(this.entityType).then(() => {
        this.$bvModal.hide('facility-add-modal')
        this.$refs['facilities-select'].getFacilities()
      })
    },
  },
}
</script>

<style lang="scss">

</style>
