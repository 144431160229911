<template>
  <!-- eslint-disable vue/no-template-shadow -->
  <validation-observer v-slot="{handleSubmit}">
    <form-wizard
      color="#00A5AF"
      shape="square"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3 p-3"
      @on-complete="handleSubmit(insertInpo)"
    >
      <tab-content
        :before-change="()=>{
          if($refs.inpoDetails.$data.flags.invalid)
            $refs.inpoDetails.handleSubmit();
          return !$refs.inpoDetails.$data.flags.invalid
        }"
        title="Organization Details"
      >
        <validation-observer ref="inpoDetails">
          <inpo-details
            :inpo-details="inpoData"
            :options="options"
            :languages="activeLanguages"
            :donation-categories="activeDonationCategories"
            :classifications-options="activeClassifications"
            :sub-classifications="subClass"
          />
        </validation-observer>
        <div class="btn-back">
          <back />
        </div>
      </tab-content>

      <tab-content
        :before-change="()=>{
          if($refs.inpoSocial.$data.flags.invalid)
            $refs.inpoSocial.handleSubmit();
          return !$refs.inpoSocial.$data.flags.invalid
        }"
        title="Social"
      >
        <validation-observer ref="inpoSocial">
          <inpo-social
            :inpo-details="inpoData"
            :tag-options="activeTags.filter(tag=>tag.category === 'islamic organization')"
          />
        </validation-observer>
      </tab-content>

      <tab-content
        :before-change="()=>{
          if($refs.paymentDetails.$data.flags.invalid)
            $refs.paymentDetails.handleSubmit();
          return !$refs.paymentDetails.$data.flags.invalid
        }"
        title="Payment Details"
      >
        <validation-observer ref="paymentDetails">
          <payment-details
            :payment-details-data="inpoData"
          />
          <b-form-row>
            <b-col cols="12">
              <upload-image
                id="inpo-signature"
                label="Signature"
                :images.sync="inpoData.sign_path"
              />
            </b-col>

            <b-col cols="12">
              <images-base-64-preview
                file-id="inpo-signature"
                :images.sync="inpoData.sign_path"
              />
            </b-col>
          </b-form-row>
        </validation-observer>
      </tab-content>

      <upload-progress :progress="progress" />
    </form-wizard>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import inpoDetails from '@/common/components/Inpos/Add/InpoDetails.vue'
import InpoSocial from '@/common/components/Inpos/Add/InpoSocial.vue'
import paymentDetails from '@/common/components/Inpos/Add/PaymentDetails.vue'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'
import UploadImage from '@/common/components/common/FormInputs/UploadImage.vue'

import getOrganizationDetails from '@/common/compositions/GeneralSettings/getGeneralSettings'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import Back from '@/common/components/common/FormInputs/Back.vue'
import ImagesBase64Preview from '@/common/components/common/FormInputs/ImagesBase64Preview.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    inpoDetails,
    InpoSocial,
    paymentDetails,
    UploadProgress,
    UploadImage,
    Back,
    ImagesBase64Preview,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inpoData: {
        name: '',
        lat: '',
        lng: '',
        logo: [],
        cover: [],
        about: '',
        phone_number: '',
        email: '',
        social_facebook: '',
        social_twitter: '',
        social_instagram: '',
        social_youtube: '',
        website: '',
        classifications: [1],
        subclassifications: [],
        address_type: '1',
        street: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        tag_description: '',
        language_id: '',
        tag_id: '',
        payment_type: '',
        business_type: '',
        business_name: '',
        legal_name: '',
        account_holder_name: '',
        account_holder_position: '',
        sign_path: [],
        account_holder_dofb: '',
        transit_number: '',
        institution_number: '',
        account_number: '',
        phoneNum: '',
        charitable_number: '',
        donation_cats_id: '',
        contact_name: '',
        servicesIDs: [],
        facilitiesIDs: [],
        taxReceiptAddress: {},
        has_prayers: true,
      },
      options: ['English', 'Arabic', 'German', 'other'],
      autocomplete: '',
      place: '',
      classifications: [
        {
          id: 1,
          name: 'Organization',
        },
      ],
    }
  },
  setup() {
    const { progress, calculateUploadPercentage } = uploadPercentage()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {

      successfulOperationAlert,
    } = handleAlerts()

    const {
      activeDonationCategories,
      activeLanguages,
      activeTags,
      activeClassifications,
    } = getOrganizationDetails()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      activeDonationCategories,
      activeLanguages,
      activeTags,
      activeClassifications,

      successfulOperationAlert,
      progress,
      calculateUploadPercentage,
    }
  },
  computed: {
    ...mapGetters({ subClass: 'generalSettings/getActiveSubClassifications' }),
  },
  created() {
    this.$store.dispatch('generalSettings/getClassSubClassificationRequest', this.classifications[0].id)
  },
  methods: {
    insertInpo() {
      this.swapLatAndLong()
      this.$entities
        .post('internalops/entity', {
          ...this.inpoData,
          parentID: this.inpoData.parent?.id,
          logo: this.inpoData.logo[0],
          cover: this.inpoData.cover[0],
          sign_path: this.inpoData.sign_path[0],
          street_tax: this.inpoData.taxReceiptAddress.street,
          city_tax: this.inpoData.taxReceiptAddress.city,
          state_tax: this.inpoData.taxReceiptAddress.state,
          country_tax: this.inpoData.taxReceiptAddress.country,
          postal_code_tax: this.inpoData.taxReceiptAddress.postal_code,
          lat_tax: this.inpoData.taxReceiptAddress.lat,
          lng_tax: this.inpoData.taxReceiptAddress.lng,
          facilitiesIDs: this.inpoData.facilities?.map(item => item.id),
          servicesIDs: this.inpoData.services?.map(item => item.id),
          productsIDs: this.inpoData.products?.map(item => item.id),
          programsIDs: this.inpoData.programs?.map(item => item.id),
          has_prayers: this.inpoData.hasPrayers,
        }, {
          onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
        })
        .then(() => {
          this.successfulOperationAlert('Added Successfully')
          this.$router.push('/inpos/list')
        }).catch(err => {
          const duplicationError = err.response.data?.errors?.name[0]
          const [message, id] = duplicationError.split(',')
          if (message === 'Entity with same details already exist') {
            if (this.$can('update', 'global-EntitiesController')) {
              this.$router.push({ name: 'admin-inpo-edit', params: { id: parseInt(id, 10) } })
            } else if (this.$can('update', 'EntitiesController')) {
              this.$router.push({ name: 'inpo-edit', params: { id: parseInt(id, 10) } })
            }
          }
        })
    },
    swapLatAndLong() {
      [this.inpoData.lat, this.inpoData.lng] = [this.inpoData.lng, this.inpoData.lat] // this is because api reverse them
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
